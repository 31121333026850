import React, { FC } from 'react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

import { isBrowser } from 'utils/browser';

import { ISubMenuItem } from './model';

const SubMenuItem: FC<ISubMenuItem> = ({ name, linkToSection }) => {
  const href = `#${linkToSection}`;
  const activeClass = isBrowser() && document.location.hash === href ? 'sub-menu__active' : '';

  return (
    <li key={name}>
      <AnchorLink className={activeClass} to={href}>
        {name}
      </AnchorLink>
    </li>
  );
};

export default SubMenuItem;
