import { useEffect } from 'react';
import ScrollService from 'services/scrollService';
import { isBrowser } from 'utils/browser';

const useActiveLinks = (
  topOffset: number,
  menuClass: string = 'sub-menu__list',
  activeClass: string = 'sub-menu__active'
): void => {
  useEffect(() => {
    let scroller;
    setTimeout(() => {
      if (isBrowser()) {
        scroller = new ScrollService(`.${menuClass}`, { activeClass, topOffset });
      }
    }, 1000);

    return () => {
      if (scroller) {
        scroller.destroy();
      }
    };
  }, []);
};

export default useActiveLinks;
