import React, { FC } from 'react';

import UniversalImage from 'components/common/UniversalImage';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { BrandedProps } from './model';
import './Branded.scss';

const Branded: FC<BrandedProps> = React.memo(({ brands }) =>
  brands?.length > 0 ? (
    <ul className="nf-branded">
      {brands.map(({ properties: { icon, text } }) => (
        <li key={text}>
          <UniversalImage imageAlt={text} img={icon} />
          <DangerouslySetInnerHtml html={text} className="nf-branded__text" />
        </li>
      ))}
    </ul>
  ) : null
);

export default Branded;
