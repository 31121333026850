import React, { FC } from 'react';
import { graphql, PageProps } from 'gatsby';

import bodyRenderer from 'utils/bodyRenderer';
import Layout from 'components/Layout';
import Banner from 'components/Banner';
import WhyNurofen from 'components/WhyNurofen';
import WhyNurofenText from 'components/WhyNurofenText';
import NurofenStory from 'components/NurofenStory';
import SubMenu from 'components/SubMenu';
import { parseBanner } from 'components/Banner/parsers';
import OrderedList from 'components/OrderedList';

import 'templates/WhyNurofenPage/why-nurofen.scss';

const elements: AppPageDataTypes.TBodyElements = {
  'Info Banner': (properties, keyId) => (
    <div id={properties?.submenuLink?.[0]?.properties?.submenuItemAnchor} key={keyId}>
      <WhyNurofen
        key={keyId}
        title={properties?.title}
        titleColor={properties?.infoBannerTitleTextColor}
        listDotColor={properties?.infoBannerBulletColor}
        bgColor={properties?.infoBannerBgColor}
        content={properties?.description}
        contentBottom={properties?.descriptionBottom}
        mainImg={properties?.image}
        mainImgAlt={properties?.infoBannerImageAlt}
        brands={properties?.infographics}
        hideImageOnMobile={properties?.infoBannerHideImageOnMobile === '1'}
      />
    </div>
  ),
  Textbox: (properties, keyId) => (
    <WhyNurofenText
      key={keyId}
      textboxText={properties?.textboxText}
      textboxBgColor={properties?.textboxBgColor}
      textboxBulletsColor={properties?.textboxBulletsColor}
      textboxImage={properties?.textboxImage}
      textboxImageAlt={properties?.textboxImageAlt}
    />
  ),
  'Related content': (properties, keyId) => (
    <div
      id={properties?.submenuLink?.[0]?.properties?.submenuItemAnchor}
      className="nurofen-story-holder"
      key={keyId}
    >
      <NurofenStory
        title={properties?.relatedContentTitle}
        bgBlockColor={properties?.relatedContentBgColor}
        storyTabs={properties?.relatedContentTabs}
        relatedContentTabPrev={properties.relatedContentTabPrev}
        relatedContentTabNext={properties.relatedContentTabNext}
      />
    </div>
  ),
  'Article Ordered List': ({ orderedList, title, limit, pointsColor }, keyId) => (
    <OrderedList
      key={keyId}
      orderedList={orderedList}
      title={title}
      limit={limit}
      pointsColor={pointsColor}
    />
  ),
};

interface IWhyNurofenPage extends PageProps {
  pageContext: { breadcrumbs: UmbracoTypes.IBreadCrumb[] };
  data: WhyNurofenTypes.IPageData;
}

const WhyNurofenPage: FC<IWhyNurofenPage> = ({
  pageContext: { breadcrumbs },
  data: {
    page: {
      whyNurofenBanner,
      whyNurofenBody,
      seoMetaTitle,
      seoMetaDescription,
      seoMetaKeywords,
      seoExternalHreflangs,
      ogImage,
      submenuAriaLabel,
      lang,
    },
    header,
    footer,
    siteSettings,
    covidBanner,
    newsletterPopupDefault,
    newsletterPopupDiscount,
    newsletterPopupFreeDelivery,
    disclaimerPopup,
    searchPath,
    brandSettings,
    legalPopup,
    pageLangs: { nodes: langs },
  },
}) => {
  const subMenuLinks = whyNurofenBody
    .filter(({ properties }) => properties.submenuLink)
    .map(
      ({
        properties: {
          submenuLink: [
            {
              properties: { submenuItemAnchor, submenuItemName },
            },
          ],
        },
      }) => ({
        linkToSection: submenuItemAnchor,
        name: submenuItemName,
      })
    );

  const whyNurofenBodyContent = bodyRenderer(whyNurofenBody, elements);

  return (
    <Layout
      seo={{
        seoMetaTitle,
        seoMetaDescription,
        seoMetaKeywords,
        seoExternalHreflangs,
        ogImage,
      }}
      header={header}
      footer={footer}
      siteSettings={siteSettings}
      covidBanner={covidBanner}
      newsletterPopupDefault={newsletterPopupDefault}
      newsletterPopupDiscount={newsletterPopupDiscount}
      newsletterPopupFreeDelivery={newsletterPopupFreeDelivery}
      disclaimerPopup={disclaimerPopup}
      searchPath={searchPath}
      brandSettings={brandSettings}
      langSettings={{
        currentLang: lang,
        langs,
      }}
      legalPopup={legalPopup}
      className="has-sticky-nav"
    >
      <section className="why-nurofen-page">
        <Banner {...parseBanner(whyNurofenBanner[0].properties)} breadcrumbs={breadcrumbs} />
        <SubMenu ariaLabel={submenuAriaLabel} links={subMenuLinks} topOffset={160} />
        {whyNurofenBodyContent}
      </section>
    </Layout>
  );
};

export const query = graphql`
  query($lang: String!, $pageBannerImageSize: Int = 1000, $pageIdRegex: String) {
    page: whyNurofen(lang: { eq: $lang }) {
      ...FragmentSeo
      lang
      submenuAriaLabel
      whyNurofenBanner {
        properties {
          ...FragmentBanner
        }
      }
      whyNurofenBody {
        structure
        properties {
          ...FragmentWhyNurofen
          ...FragmentRelatedContent
          ...FragmentOrderedListI
          ...FragmentTextbox
        }
      }
    }
    pageLangs: allWhyNurofen(filter: { umbracoId: { regex: $pageIdRegex } }) {
      nodes {
        link
        lang
      }
    }
    ...FragmentCommonCompositions
  }
`;

export default WhyNurofenPage;
