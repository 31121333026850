import React, { FC, useCallback, useRef, useState } from 'react';
import { graphql } from 'gatsby';
import { useSwipeable } from 'react-swipeable';

import Container from 'react-bootstrap/Container';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import UniversalImage from 'components/common/UniversalImage';
import CustomTabs from 'components/common/CustomTabs';
import IconCustom from 'components/common/IconCustom';
import TabTitle from 'components/NurofenStory/TabTitle';

import { INurofenStoryComponentProps } from './model';

import './NurofenStory.scss';
import { isBrowser } from '../../utils/browser';

const NurofenStory: FC<INurofenStoryComponentProps> = ({
  title,
  bgBlockColor,
  storyTabs,
  relatedContentTabPrev,
  relatedContentTabNext,
}) => {
  const tabsRef = useRef<HTMLDivElement>(null);
  const [tabIndex, setTabIndex] = useState<number>(0);

  const scrollToTabTop = () => {
    const offsetTopTab = tabsRef.current?.getBoundingClientRect().top || 0;
    if (isBrowser()) {
      window.scrollTo({
        top: offsetTopTab + window.pageYOffset - 150,
        behavior: 'smooth',
      });
    }
  };

  const setSelectedTab = useCallback((param) => {
    scrollToTabTop();

    return () => setTabIndex(param);
  }, []);

  const handleSelect = (index) => {
    scrollToTabTop();
    setTabIndex(index);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => (tabIndex < storyTabs.length - 1 ? setTabIndex(tabIndex + 1) : undefined),
    onSwipedRight: () => (tabIndex > 0 ? setTabIndex(tabIndex - 1) : undefined),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <div className="nurofen-story-wrapper" ref={tabsRef}>
      <div
        className="nurofen-story"
        style={
          bgBlockColor &&
          ({
            backgroundColor: `var(--${bgBlockColor?.[0]?.properties?.colorPicker?.label})`,
          } as React.CSSProperties)
        }
        data-test="NurofenStory"
      >
        <Container fluid>
          <h2 className="nurofen-story__title">{title}</h2>
          <div className="nurofen-story__story-tab">
            <CustomTabs
              onSelectCallback={handleSelect}
              selectedIndex={tabIndex}
              items={storyTabs.map(({ properties }) => ({
                tabTitle: {
                  name: properties?.tabTitle,
                  ariaLabel: properties?.tabAriaLabel,
                },
                content: (
                  <div className="nurofen-story__tab-slide" {...handlers}>
                    {properties?.tabImage ? (
                      <div className="nurofen-story__tab-slide-img">
                        <UniversalImage
                          imageAlt={properties?.tabImageAlt}
                          img={properties?.tabImage}
                          objectFitData={{ objectFit: 'contain' }}
                        />
                      </div>
                    ) : null}
                    <div className="nurofen-story__tab-slide-content">
                      <TabTitle
                        title={properties?.tabTitle}
                        classes="nurofen-story__tab-slide-title"
                      />
                      <span className="nurofen-story__tab-slide-subtitle">
                        {properties?.tabSubtitle}
                      </span>
                      <DangerouslySetInnerHtml
                        html={properties?.tabText}
                        className="nurofen-story__tab-slide-text"
                      />
                    </div>
                  </div>
                ),
              }))}
            />
            <div className="nurofen-story__nav-buttons">
              <button
                type="button"
                aria-label={relatedContentTabPrev}
                disabled={tabIndex === 0}
                onClick={tabIndex > 0 ? setSelectedTab(tabIndex - 1) : undefined}
                className="nf-articles-navigator nf-articles-navigator__prev"
              >
                <IconCustom
                  icon={
                    tabIndex === 0 ? 'carousel_arrow disabled-carousel_arrow' : 'carousel_arrow'
                  }
                />
              </button>

              <button
                type="button"
                aria-label={relatedContentTabNext}
                disabled={tabIndex === storyTabs.length - 1}
                onClick={tabIndex < storyTabs.length - 1 ? setSelectedTab(tabIndex + 1) : undefined}
                className="nf-articles-navigator nf-articles-navigator__next nf-articles-navigator--disabled"
              >
                <IconCustom
                  icon={
                    tabIndex === storyTabs.length - 1
                      ? 'carousel_arrow disabled-carousel_arrow'
                      : 'carousel_arrow'
                  }
                />
              </button>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export const query = graphql`
  fragment FragmentRelatedContent on IRelatedContent {
    relatedContentTitle
    relatedContentBgColor {
      ...FragmentColorProps
    }
    relatedContentTabPrev
    relatedContentTabNext
    relatedContentTabs {
      properties {
        tabImageAlt
        tabSubtitle
        tabText
        tabTitle
        tabAriaLabel
        tabImage {
          fallbackUrl
          url
          gatsbyImage {
            childImageSharp {
              fluid(maxWidth: 450) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          svg {
            content
          }
        }
      }
    }
    submenuLink {
      properties {
        submenuItemAnchor
        submenuItemName
      }
    }
  }
`;

export default NurofenStory;
