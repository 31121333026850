export const bodyRenderer = <T>(
  body: AppPageDataTypes.IPageBodyItem<T>[],
  elements: AppPageDataTypes.TBodyElements,
  additionalProps?: Record<string, any>
) =>
  body?.length > 0
    ? body.map(({ properties, structure }, index) => {
        const keyId = `${structure}_${index}`;

        return elements[structure]
          ? elements[structure]({ ...additionalProps, ...properties }, keyId)
          : null;
      })
    : null;

export default bodyRenderer;
