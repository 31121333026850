import { isBrowser } from 'utils/browser';

interface IScrollParams {
  activeClass?: string;
  headerHeight?: null | number;
  topOffset: number;
}

interface IScrollService {
  destroy: () => void;
}

class ScrollService implements IScrollService {
  private menuSelector: string | undefined;

  private menu: Element | undefined;

  private links: Element[] = [];

  private nameAttribute: string = 'link-name';

  private handleScroll: () => void = () => {};

  private params: IScrollParams = {
    activeClass: 'active',
    headerHeight: null,
    topOffset: 0,
  };

  constructor(menuSelector: string, args?: IScrollParams) {
    if (menuSelector.length < 1) return;

    this.menuSelector = menuSelector;
    this.params = {
      ...this.params,
      ...args,
    };

    if (isBrowser()) {
      const menu = document.querySelectorAll(this.menuSelector)[0];

      if (this.params.headerHeight === null) {
        this.params.headerHeight = menu.clientHeight;
      }
      this.menu = menu;
      this.links = this.getLinks();
      this.setNames(this.links);

      this.onScrollLinks(this.links);
      this.handleScroll = () => this.onScrollLinks(this.links);

      document.addEventListener('scroll', this.handleScroll);
    }
  }

  public destroy(): void {
    document.removeEventListener('scroll', this.handleScroll);
  }

  private getLinks(): Element[] {
    if (typeof this.menu === 'undefined') {
      return [];
    }
    const links = Array.from(this.menu.querySelectorAll('li a'));

    return links;
  }

  private setNames(links: Element[]): void {
    links.forEach((link: Element) => {
      const href = link.getAttribute('href');

      if (!href || href.indexOf('#') === -1) return;
      const name = href.split('#')[1];

      link.setAttribute(this.nameAttribute, name);
    });
  }

  private onScrollLinks(links: Element[]): void {
    links.forEach((link: Element) => {
      const name = link.getAttribute(this.nameAttribute);
      if (name === null) return;

      const target = document.getElementById(name);

      if (target === null) return;

      link.classList.remove(this.params.activeClass || 'active');

      const { top, height } = target.getBoundingClientRect();
      const topAdjusted = top - this.params.topOffset;
      if (Math.floor(topAdjusted) <= 0 && Math.abs(Math.floor(topAdjusted)) < Math.floor(height)) {
        link.classList.add(this.params.activeClass || 'active');
      }
    });
  }
}

export default ScrollService;
