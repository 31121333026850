import React, { FC, useEffect } from 'react';

import useActiveLinks from 'hooks/useActiveLinks';

import SubMenuItem from './SubMenuItem';
import { ISubMenuComponentProps } from './model';
import './SubMenu.scss';

const SubMenu: FC<ISubMenuComponentProps> = ({ links, ariaLabel, topOffset }) => {
  useActiveLinks(topOffset);

  useEffect(() => {
    window._disableUpdateScroll = true;

    return () => {
      window._disableUpdateScroll = false;
    };
  }, []);

  return (
    <nav className="sub-menu" aria-label={ariaLabel} data-test="SubMenu">
      <ul className="sub-menu__list">
        {links.map((item) => (
          <SubMenuItem key={item.name} {...item} />
        ))}
      </ul>
    </nav>
  );
};

export default SubMenu;
