import React, { FC, ReactElement } from 'react';

import { IPropsTabTitle } from './model';

const TabTitle: FC<IPropsTabTitle> = (props): ReactElement | null => {
  const { title, classes } = props;

  return (
    <strong data-test="TabTitle" className={classes}>
      {title}
    </strong>
  );
};

export default TabTitle;
