import React, { FC, ReactElement } from 'react';

import { IPropsWhyNurofenTitle } from './model';

const WhyNurofenTitle: FC<IPropsWhyNurofenTitle> = (props): ReactElement | null => {
  const { title, classes } = props;

  return (
    <strong
      data-test="WhyNurofenTitle"
      className={classes}
      dangerouslySetInnerHTML={{ __html: title }}
    />
  );
};

export default WhyNurofenTitle;
